<template>
  <b-form-group
    label="Rapor"
    label-for="id_reports"
  >
    <validation-provider
      #default="{ errors }"
      name="Rapor"
      rules="required"
    >
      <v-select
        id="id_reports"
        v-model="itemData.id_reports"
        :options="dataList"
        label="title"
        :reduce="item => item.id"
        placeholder="Rapor"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ButtonText',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    itemData() {
      return this.$store.getters['modReports/dataItem']
    },
    dataList() {
      return this.$store.getters['reports/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('reports/getDataList', {
        select: [
          'reports.id AS id',
          'reports.title AS title',
        ],
        limit: 10,
        start: 0,
      })
    },
  },
}
</script>
